import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading from '../utils/common/Heading';
import media from '../utils/helpers/media';
import PaymentsPhone from '../components/Home/PaymentsPhone';
import PaymentsInfo from '../components/Home/PaymentsInfo';
import useMedia from '../hooks/useMedia';

const PageWrapper = styled.div`
  background: linear-gradient(to top, rgba(237, 239, 255, 0.37), #ffffff);
  margin: 0 auto;
`;

const PaymentsContainer = styled.div`
  padding-top: 50px;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 678px;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;

  ${media.phone`
    padding-top: 5px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    overflow: none;
    position: relative;
  `};
`;

const IndexPage = () => {
  const small = useMedia('(max-width: 576px)');
  return (
    <Layout>
      <SEO title="Условия страхования" />
      <PageWrapper>
        {small && <Heading text="Как Манго выплачивает деньги" center />}
        <PaymentsContainer>
          <PaymentsPhone />
          <PaymentsInfo />
        </PaymentsContainer>
      </PageWrapper>
    </Layout>
  );
};

export default IndexPage;
