import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import phoneImg from '../../assets/images/phoneHome.png';
import phoneSmall from '../../assets/images/mobilePhone.png';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const PhoneContainer = styled.div`
  img {
    height: 631px;
    width: 424px;
  }
  ${media.phone`
      position: sticky;
      top:0;
      z-index: 1;
      height: 100%;

      img{
        height: 294px;
        width: 204px;
      }

  `};
`;

const PaymentsPhone = () => {
  const large = useMedia('(min-width: 577px)');
  const small = useMedia('(max-width: 576px)');
  return (
    <>
      {large && (
        <Fade bottom>
          <PhoneContainer>{<img src={phoneImg} alt="phone" />}</PhoneContainer>
        </Fade>
      )}
      {small && (
        <PhoneContainer>
          <img src={phoneSmall} alt="#" />
        </PhoneContainer>
      )}
    </>
  );
};

export default PaymentsPhone;
