import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Heading from '../../utils/common/Heading';
import cameraIcon from '../../assets/icons/camera.svg';
import carIcon from '../../assets/icons/doc.svg';
import coinIcon from '../../assets/icons/coin.svg';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const options = [
  {
    id: 1,
    text:
      'Отправьте через личный кабинет фото или видео того, что случилось в квартире.',
    icon: cameraIcon
  },
  {
    id: 2,
    text: 'Укажите оценочную стоимость потерь.',
    icon: carIcon
  },
  {
    id: 3,
    text:
      'Получите выплату на карту, в 90% случаев отправляем деньги на карту в течение дня.',
    icon: coinIcon
  }
];

const PaymentsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 60px;

  ${media.phone`
    margin-left: 0;
    margin-top: 0;
    padding: 0 50px 40px 50px;
    align-items: center;
    background: linear-gradient(
    to top,
    rgba(237, 239, 255, 0.7) 0%,
    #fff,
    rgba(255,255,255,.7) 100%
  );
    width: 100%;
    z-index: 2;
  `};
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;

  ${media.phone`
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5px 0;
    
  ;`}

  img {
    margin-right: 40px;

    ${media.phone`
      width: 3rem;
      height: 5rem;
  ;`}

    ${media.phone`
      margin-right: 0;
      margin-bottom: 17px;
  ;`}
  }

  .text-wrapper {
    height: 50px;
    text-align: left;

    ${media.phone`
  ;`}
  }

  .text {
    margin-bottom: 30px;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.44px;
    width: 383px;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 60px;

    ${media.phone`
      font-size: 15px;
      line-height: 20px;
      width: 287px;
      justify-content: center;
      text-align: center;
      margin-bottom: 0;

  ;`}
  }
`;

const Options = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  ${media.phone`
      width: unset;
      margin-bottom:0;
  ;`}
`;

const PaymentsInfo = () => {
  const large = useMedia('(min-width: 577px)');
  return (
    <PaymentsContainer>
      {large && <Heading text="Как получить выплату" center={false} />}
      <Options>
        <Fade>
          {options.map(option => (
            <OptionWrapper key={option.id}>
              <img src={option.icon} alt="#" />
              <div className="text-wrapper">
                <p className="text">{option.text}</p>
              </div>
            </OptionWrapper>
          ))}
        </Fade>
      </Options>
    </PaymentsContainer>
  );
};

export default PaymentsInfo;
